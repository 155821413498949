export const FILTER_ADD_SEARCH_TERM = 'FILTER_ADD_SEARCH_TERM';
export const FILTER_REMOVE_SEARCH_TERM = 'FILTER_REMOVE_SEARCH_TERM';

export const FILTER_ADD_RANGE_PRICE = 'FILTER_ADD_RANGE_PRICE';
export const FILTER_REMOVE_RANGE_PRICE = 'FILTER_REMOVE_RANGE_PRICE';

export const FILTER_ADD_CATEGORY = 'FILTER_ADD_CATEGORY';
export const FILTER_REMOVE_CATEGORY = 'FILTER_REMOVE_CATEGORY';

export const FILTER_ADD_SIZE = 'FILTER_ADD_SIZE';
export const FILTER_REMOVE_SIZE = 'FILTER_REMOVE_SIZE';

export const FILTER_ADD_BRAND = 'FILTER_ADD_BRAND';
export const FILTER_REMOVE_BRAND = 'FILTER_REMOVE_BRAND';

export const FILTER_CLEAR_ALL = 'FILTER_CLEAR_ALL';
