export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST';
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST';
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS';
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL';
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET';

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET';

export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST';
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS';
export const PRODUCT_CREATE_REVIEW_FAIL = 'PRODUCT_CREATE_REVIEW_FAIL';
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET';

export const PRODUCT_TOP_REQUEST = 'PRODUCT_TOP_REQUEST';
export const PRODUCT_TOP_SUCCESS = 'PRODUCT_TOP_SUCCESS';
export const PRODUCT_TOP_FAIL = 'PRODUCT_TOP_FAIL';

export const PRODUCT_LATEST_REQUEST = 'PRODUCT_LATEST_REQUEST';
export const PRODUCT_LATEST_SUCCESS = 'PRODUCT_LATEST_SUCCESS';
export const PRODUCT_LATEST_FAIL = 'PRODUCT_LATEST_FAIL';

export const PRODUCT_SALE_REQUEST = 'PRODUCT_SALE_REQUEST';
export const PRODUCT_SALE_SUCCESS = 'PRODUCT_SALE_SUCCESS';
export const PRODUCT_SALE_FAIL = 'PRODUCT_SALE_FAIL';

export const PRODUCT_RELATED_REQUEST = 'PRODUCT_RELATED_REQUEST';
export const PRODUCT_RELATED_SUCCESS = 'PRODUCT_RELATED_SUCCESS';
export const PRODUCT_RELATED_FAIL = 'PRODUCT_RELATED_FAIL';

export const PRODUCT_SORT_BY_PRICE_REQUEST = 'PRODUCT_SORT_BY_PRICE_REQUEST';
export const PRODUCT_SORT_BY_PRICE_SUCCESS = 'PRODUCT_SORT_BY_PRICE_SUCCESS';
export const PRODUCT_SORT_BY_PRICE_FAIL = 'PRODUCT_SORT_BY_PRICE_FAIL';

export const PRODUCT_SHOP_REQUEST = 'PRODUCT_SHOP_REQUEST';
export const PRODUCT_SHOP_SUCCESS = 'PRODUCT_SHOP_SUCCESS';
export const PRODUCT_SHOP_FAIL = 'PRODUCT_SHOP_FAIL';
export const PRODUCT_SHOP_FILTER = 'PRODUCT_SHOP_FILTER';
