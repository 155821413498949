const categories = [
  'T-Shirt',
  'Hoodies & Pullovers',
  'Jackets & Coats',
  "Men's Shirts",
  "Women's Shirts",
  'Pants & Jeans',
  'Short',
  'Dresses',
  'Shoes',
];

export default categories;
